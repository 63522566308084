<template>
  
  <div>
    <HeaderComponent />
    <section class="login-section">
      <h1>Request password reset</h1>
      <form
        class="login-form"
        @submit.prevent="handleSignup"
      >
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          required
          class="input-field"
        >
        <button
          type="submit"
          class="submit-button"
        >
          reset 
        </button>
      </form>
      <InfoBox 
        v-if="showInfoBox" 
        :title="infoBoxTitle" 
        :message="infoBoxMessage" 
        @close="handleCloseBox" 
      />
    </section>
  </div>
</template>

<script>

import HeaderComponent from './HeaderComponent.vue';
import { userService } from '../services/UserService'; // Import du service
import InfoBox from './InfoBox.vue'; // Import du composant InfoBox


export default {
  name: 'RequestResetPage',
  components: {
    InfoBox,
    HeaderComponent,


  },
  data() {
    
    return {
      email: '',
    };
  },
  methods: {
    handleSignup() {
      // Créer un objet avec les données du formulaire
      const userData = {
          email: this.email    
      };

      // Appel à l'API pour l'inscription
      userService.requestReset(userData)
        .then(() => {
          console.log()
          this.infoBoxTitle = 'Mail reset passwor send';
          this.infoBoxMessage = 'An email has been sent to your address. Please click on the validation link in this email to complete your registration.';
          this.showInfoBox = true; // Afficher la boîte de message
        })
        .catch(error => {
          // Affichage d'une erreur si l'inscription échoue
          alert(`An error occurred during registration. Please try again. message :${error.message}`);
        });
    },
    handleCloseBox() {
      // Rediriger vers la page d'accueil quand la boîte est fermée
      this.$router.push('/');
    },
  }
    
  
};
</script>

<style scoped>
/* Global form section styles */
.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
  background-color: #f7f7f7;
  padding: 20px;
}
.error_form {
  color: #741f1f;
  font-size: 0.7rem;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

/* Form container */
.login-form {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input field styling */
.input-field {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #4fdab5; /* Greenish focus effect */
  outline: none;
}

.input-field::placeholder {
  color: #888;
}

/* Submit button */
.submit-button {
  padding: 12px;
  font-size: 18px;
  background-color: #4fdab5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3cb99a; /* Darker shade on hover */
}

.submit-button:active {
  background-color: #2d856c; /* Even darker on click */
}

.password-field {
  position: relative;
  background-color: #f9f9f9;
}

.password-input-wrapper {
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
}

.password-input {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 40px 8px 12px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  line-height: 0;
  color: #666;
}

.toggle-password:hover {
  color: #333;
}

/* Retirer les styles par défaut des inputs */
.password-input:focus {
  outline: none;
  border-color: #4CD4A1; /* Couleur verte de votre bouton Login */
}
/* Responsiveness */
@media (max-width: 768px) {
  .login-form {
    width: 90%;
  }
}
</style>
