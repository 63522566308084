<template>
  <div>
    <HeaderComponent />
    <section>
      <h1>Login</h1>
      <form @submit.prevent="handleLogin" >
        <div class="login-form">
          <div class="input-container"> <!-- Nouveau container -->

          <input
            v-model="email"
            type="email"
            placeholder="Enter your email"
            required
            class="input-field"
            autocomplete="email"
          >
          <div class="password-input-wrapper">
            <button 
              @click="togglePasswordVisibility"
              type="button"
              class="toggle-password"
            >
            <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
              <circle cx="12" cy="12" r="3"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/>
              <line x1="1" y1="1" x2="23" y2="23"/>
            </svg>
          </button>
          <input 
            :type="showPassword ? 'text' : 'password'"
            v-model="password" 
            @input="checkPassword"
            class="input-field"
            placeholder="Enter your password"
            autocomplete="current-password"
          >
    
        </div>
          </div>
          
      </div>
      <div >
            <button type="submit"  class="submit-button">
            Login
            </button>  
          </div>
      </form>
      
      <InfoBox 
        v-if="showInfoBox" 
        :title="infoBoxTitle" 
        :message="infoBoxMessage" 
        @close="handleCloseBox" 
      />
    </section>
  </div>
  <div>
    <section><br/><br/><br/>
            <router-link to="/user/request_reset">
              Password forgotten
            </router-link>
    </section>
  </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import { userService } from '../services/UserService'; // Import du service
import InfoBox from './InfoBox.vue'; // Import du composant InfoBox

export default {
  name: 'LoginPage',
  components: {
    HeaderComponent,
    InfoBox
  },
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      showInfoBox:false,
    };
  },
  methods: {
    handleLogin() {
      // Handle login logic (e.g., authentication API)
      const userData = {
        
        email: this.email,
        password: this.password,
        
      };

      // Appel à l'API pour l'inscription
      userService.login(userData)
        .then((response) => {
          if (response.success) {
              // Redirection après connexion réussie
              this.$router.push('/dashboard'); // ou votre route de destination
          }
        })
        .catch(error => {
          // Affichage d'une erreur si l'inscription échoue
          this.infoBoxTitle = 'Error';
          console.log('error.response',error.response)
            if(error.response && error.response.data && error.response.data.message){
                if(error.response.data.code=="AUTH003004"){
                    this.infoBoxMessage = `An error occurred. ${error.response.data.message}`;
                }else{
                    this.infoBoxMessage = `An error occurred while validating your account. Please try again later. error.msg=${error.message}`;
                }
            }else{
                this.infoBoxMessage = `An error occurred while validating your account. Please try again later. error.msg=${error.message}`;
            }
            this.showInfoBox = true;
        });
      
    },
    handleCloseBox() {
      console.log("showInfoBox close")
      this.showInfoBox = false;
        
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },

};
</script>

<style scoped>
section {
  margin-top: 50px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
input, button {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}
button {
  background-color: var(--button-color);
  border: none;
  top: 50%;
}
button:hover {
  background-color: var( --primary-color);
}
.login-form{
  display: flex;
  justify-content: center;
}
.input-container {
  display: flex;
  gap: 20px; /* Espace entre les champs */
  margin-bottom: 20px;
  width: 50%;
}

.input-container > input,
.password-input-wrapper {
  flex: 1; /* Les deux éléments prennent la même largeur */
}

.password-input-wrapper {
  margin: 0; /* Retire la marge si elle existe */
}

/* Assurez-vous que les inputs ont la même hauteur */
.input-field {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.password-input-wrapper {
  position: relative;

}

.password-input {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 40px 8px 12px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.toggle-password {
  position: absolute;
  
  right: -18px;
  transform: translateY(-83%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  line-height: 0;
  color: #666;
}

.toggle-password:hover {
  color: #333;
}

/* Retirer les styles par défaut des inputs */
.password-input:focus {
  outline: none;
  border-color: #4CD4A1; /* Couleur verte de votre bouton Login */
}

/* Submit button */
.submit-button {
  padding: 12px;
  padding-left: 60px;
  padding-right: 60px;
  font-size: 18px;
  background-color: #4fdab5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3cb99a; /* Darker shade on hover */
}

.submit-button:active {
  background-color: #2d856c; /* Even darker on click */
}

</style>
