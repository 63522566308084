<template>
  <div>
    <HeaderComponent />
    <section class="login-section">
      <h1>Reset password</h1>
      <form
        class="login-form"
        @submit.prevent="handleReset"
      >
        <input
          v-model="token"
          type="token"
          hidden
          required
          class="input-field"
        >
        <div class="password-input-wrapper">
          <button 
            @click="togglePasswordVisibility"
            type="button"
            class="toggle-password"
          >
            <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
              <circle cx="12" cy="12" r="3"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/>
              <line x1="1" y1="1" x2="23" y2="23"/>
            </svg>
          </button>
          <input 
            :type="showPassword ? 'text' : 'password'"
            v-model="password" 
            @input="checkPassword"
            class="password-input"
            :placeholder="placeholder"
          >
        </div>
        <div class="password-input-wrapper">
          
          <button 
            @click="togglePasswordVerifVisibility"
            type="button"
            class="toggle-password"
          >
            <svg v-if="showPasswordVerif" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
              <circle cx="12" cy="12" r="3"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/>
              <line x1="1" y1="1" x2="23" y2="23"/>
            </svg>
          </button>
          <input 
            :type="showPasswordVerif ? 'text' : 'password'"
            v-model="passwordVerif" 
            @input="checkPassword"
            class="password-input"
            :placeholder="placeholder"
          >
        </div>
        <ul v-if="passwordErrors.length">
         <li v-for="error in passwordErrors" :key="error" class="error_form">{{ error }}</li>
        </ul>
        <button  :disabled="passwordErrors.length > 0 || !password " 
          type="submit"
          class="submit-button"
          :class="{ 'disabled-button': passwordErrors.length > 0 }"
        >
          reset my password
        </button>
      </form>
      <InfoBox 
        v-if="showInfoBox" 
        :title="infoBoxTitle" 
        :message="infoBoxMessage" 
        @close="handleCloseBox" 
      />
    </section>
  </div>
</template>

<script>

import HeaderComponent from './HeaderComponent.vue';
import { userService } from '../services/UserService'; // Import du service
import InfoBox from './InfoBox.vue'; // Import du composant InfoBox


export default {
  name: 'SignupPage',
  components: {
    InfoBox,
    HeaderComponent,
    

  },
  data() {
    return {
      email: '',
      password: '',
      token: '',
      passwordVerif: '',
      showInfoBox: false,
      infoBoxTitle: '',  // Titre dynamique
      infoBoxMessage: '', // Message dynamique
      passwordStrength: '',
      passwordErrors: [],
      showPassword: false,
      showPasswordVerif: false,
      placeholder:'enter your password',
      
    };
  },
  mounted() {
      // Lancer la validation lorsque la page est montée
      this.getToken();
      console.log('ValidatePage mounted');
    },
  methods: {
    getToken() {
      const token = this.$route.query.token;
      
      userService.verifyTokenReset(token).then((response) => {
            this.showProcessingPopup = false; // Cacher le popup de traitement
  
            if (response.success) {
              // Si la validation réussit
              this.token  = token 
            } else {
              // Si la validation échoue
              this.infoBoxTitle = 'Validation Failed';
              this.infoBoxMessage = `Token is expired, retry send mail`;
              this.showInfoBox = true;
            }
          })
          .catch((error) => {
            // Gérer l'erreur si l'API échoue
            console.error("response",error);
            this.showProcessingPopup = false; // Cacher le popup de traitement
            this.infoBoxTitle = 'Error';
            if(error.response && error.response.data && error.response.data.message){
                if(error.response.data.code=="AUTH002003"){
                    this.infoBoxMessage = `An error occurred while verifying the reset token of your account. ${error.response.data.message}`;
                }else{
                    this.infoBoxMessage = `An error occurred while verifying the reset token of your account. Please try again later. error.msg=${error.message}`;
                }
            }else{
                this.infoBoxMessage = `An error occurred while verifying the reset token of your account. Please try again later. error.msg=${error.message}`;
            }
           
            
            this.showInfoBox = true;

          });
    },
    
    handleReset() {
      // Créer un objet avec les données du formulaire
      const userData = {
        token: this.token,
        password: this.password, 
        passwordVerif: this.passwordVerif
        
      };

      // Appel à l'API pour l'inscription
      userService.resetPassword(userData)
        .then(() => {
          this.infoBoxTitle = 'Your Password is reset, now logging';
          this.showInfoBox = true; // Afficher la boîte de message
        })
        .catch(error => {
          // Affichage d'une erreur si l'inscription échoue
          alert(`Une erreur est survenue lors de l'inscription. Veuillez réessayer. message :${error.message}`);
        });
    },
    handleCloseBox() {
      // Rediriger vers la page d'accueil quand la boîte est fermée
      this.$router.push('/');
    },
    checkPassword() {

      const result = this.validatePassword(this.password);
      if(this.password.length>3 && this.passwordVerif!=this.password){
        result.errors.push(`The two passwords do not match. `)
      }
      this.passwordStrength = result.strength;
      this.passwordErrors = result.errors;
    },
    validatePassword(password) {
      // Initialize score and messages
      let score = 0;
      const errors = [];
      
      // Check minimum length
      if (password.length < 8) {
        errors.push("Password must contain at least 8 characters");
      } else {
        score += 1;
      }
      
      // Check uppercase letters
      if (!/[A-Z]/.test(password)) {
        errors.push("Password must contain at least one uppercase letter");
      } else {
        score += 1;
      }
      
      // Check lowercase letters
      if (!/[a-z]/.test(password)) {
        errors.push("Password must contain at least one lowercase letter");
      } else {
        score += 1;
      }
      
      // Check numbers
      if (!/[0-9]/.test(password)) {
        errors.push("Password must contain at least one number");
      } else {
        score += 1;
      }
      
      // Check special characters
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errors.push("Password must contain at least one special character");
      } else {
        score += 1;
      }
      
      // Check repeated sequences
      if (/(.)\1{2,}/.test(password)) {
        errors.push("Password must not contain consecutive repeated characters");
        score -= 1;
      }
      
      // Calculate security level
      let strength = '';
      if (score <= 2) {
        strength = 'Weak';
      } else if (score <= 4) {
        strength = 'Medium';
      } else {
        strength = 'Strong';
      }
      
      return {
        isValid: errors.length === 0,
        score: score,
        strength: strength,
        errors: errors
      };
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordVerifVisibility() {
      this.showPasswordVerif = !this.showPasswordVerif;
    }
  }
};
</script>

<style scoped>
/* Global form section styles */
.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
  background-color: #f7f7f7;
  padding: 20px;
}
.error_form {
  color: #741f1f;
  font-size: 0.7rem;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

/* Form container */
.login-form {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input field styling */
.input-field {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #4fdab5; /* Greenish focus effect */
  outline: none;
}

.input-field::placeholder {
  color: #888;
}

/* Submit button */
.submit-button {
  padding: 12px;
  font-size: 18px;
  background-color: #4fdab5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3cb99a; /* Darker shade on hover */
}

.submit-button:active {
  background-color: #2d856c; /* Even darker on click */
}

.password-field {
  position: relative;
  background-color: #f9f9f9;
}

.password-input-wrapper {
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
}

.password-input {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 40px 8px 12px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  line-height: 0;
  color: #666;
}

.toggle-password:hover {
  color: #333;
}

/* Retirer les styles par défaut des inputs */
.password-input:focus {
  outline: none;
  border-color: #4CD4A1; /* Couleur verte de votre bouton Login */
}

.submit-button:disabled {
  cursor: not-allowed;
}

.disabled-button {
  background-color: #B0B0B0; /* Gris pour le bouton désactivé */
  color: #808080;  /* Gris clair pour le texte */
}
/* Responsiveness */
@media (max-width: 768px) {
  .login-form {
    width: 90%;
  }
}
</style>
